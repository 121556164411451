.arrow-footer-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: right;
    text-decoration: none;
    span{
        color: #9d9d9d;
        font-size: 50px;
        text-decoration: none;
    }
}

footer {
    .layer-1 {
        background-color: #262626;
        min-height:50px;
        position: relative;

        .title-container {
            position: relative;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        ul {
            display: none;
            margin-bottom: 25px;

            li {
                display: inline-block;
                width: 50%;
                float: left;

                &:nth-child(odd) {
                    padding-right: 10px;
                    text-align: right;

                }
                &:nth-child(even) {
                    padding-left: 10px;
                    text-align: left;
                }
                a, span{
                    color:#9d9d9d;
                    font-size:12px;
                    line-height: 28px;
                    text-decoration: none;
                }
            }
        }

        h4{
            padding: 17px 0;
            text-align: center;
            font-size: 14px;
            color: #9d9d9d;
        }
        &.open{
            h4 {
                color: #ffffff;
            }
            ul {
                display: block
            }
        }


        .arrow {
            @extend .arrow-footer-menu;
        }

        &.open .arrow span{
            color: #fff;
        }
    }

    .layer-2 {
        background-color: #000;
        color: #9d9d9d;
        padding: 15px 0 25px 0;
        .menu {
            padding: 0;
        }
        .vo-container {
            padding: 0;
        }

        p{
            text-align: center;
        }
        
        h6 {
            font-weight: 700;
            color: #fff;
            font-size: 12px;
            position: relative;
            margin: 15px 0;
            a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: right;
                text-decoration: none;
                span{
                    color: #9d9d9d;
                    font-size: 50px;
                    text-decoration: none;
                }
            }
        }

        .section-1 {
            margin-top: -20px;
            h6{
                text-align: center;
                margin-bottom: 10px;
            }
        }

        .section-2 {
            text-align: center;
            font-size: 12px;
            line-height: 18px;
            color: #767676;
        }

        .social{
            text-align: center;
            font-size: 50px;
            a{
                text-decoration: none;
                color: #9d9d9d;
            }
        }

        hr {
            width: 100%;
            height: 1px;
            margin: 15px auto;
            background-color: rgba(157,157,157,0.25);
        }


        .subscribe-container{
            text-align: center;
            .field{
                position: relative;
                display: inline-block;
            }
            button {
                border: none;
                outline: none;
                background: none;
                font-size: 50px;
                position: absolute;
                right: -15px;
                top: -13px;
            }
            input{
                background-color: transparent;
                color: #9d9d9d;
                border: solid #9d9d9d 1px;
                padding: 10px;
                width: 160px;
                padding-right: 35px;
                font-size: 11px;
                @include placeholder {
                    text-transform: uppercase;
                    text-align: center;
                }
            }
        }

        ul.links{
            list-style:none;
            font-size:15px;

            &>li{
                min-height: 50px;
                position: relative;

                &.menu{
                    min-height: 0;
                    &>.vo-container{
                        padding: 0;
                        display: contents;                        						
                    }
                    ul:first-child .first.title {
                        border-top: 0;
                    }
                    ul.last {
                        border-bottom: solid rgba(157, 157, 157, 0.25) 1px;
                    }
                    ul:last-child{
                        li.last{
                            border-bottom: none;
                        }
                    }
                    ul:last-child{
                        margin-bottom: 30px;
                    }
                }

                &>.option{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    &::before{
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        height: 100%;
                    }
                    &>.name{
                        display: inline-block;
                        vertical-align: middle;
                    }

                }

            }

            .col>li{
                min-height: 50px;
                position: relative;

                &.title, &.subtitle{
                    font-size: 12px;
                    border-top: solid rgba(157, 157, 157, 0.25) 1px;
                }

                &.title{
                    color: #fff;
                    a {
                        color: #fff;
                        width: 100%;
                    }
                }

                &.item{
                    font-size: 12px;
                    a{
                        padding-left: 15px;
                        color: #9d9d9d;
                        text-decoration: none;
                        &.active{
                            color: #95c731;
                        }
                    }
                    &.last{
                        border-bottom: solid rgba(157, 157, 157, 0.25) 1px;
                    }
                }

                &>.option{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 0 20px;
                    &::before{
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        height: 100%;
                    }
                    &>.name{
                        display: inline-block;
                        vertical-align: middle;
                    }

                }

                .arrow{
                    font-size: 50px;
                    position: absolute;
                    right: 10px;
                    top: 0;
                    color: #fff;
                }

                &.open .arrow{
                    color: #fff;
                }
            }

        }

    }
}
