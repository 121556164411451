@import '../../widgets/reseller/mobile';

@mixin arrow{
    cursor: pointer;
    width: 50px;
    height: 90px;
    top: 50%;
    margin-top: -45px;
    border-radius: 3px;
    background-color: #000;
    color: #fff;
    opacity: 0.25;
    font-size: 50px;
    position: absolute;
    padding: 20px 0;
    &.left{
        left:0;
    }
    &.right{
        right:0;
    }
    span {
        position: absolute;
        font-style: normal;
        color: #fff;
        left: 0;
    }
}

@mixin carousel-selector($color) {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $color;
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
}

@mixin button-type-a($color) {
    background-color: transparent;
    color: $color;
    border: solid $color 1px;
    padding: 13px 10%;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

@mixin button-type-b($color) {
    background-color: $color;
    color: #fff;
    border: none;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

@mixin button-type-checkout($color) {
    background-color: $color;
    color: #fff;
    border: none;
    text-align: center;
    cursor: pointer;
    margin-bottom: 15px;
    height: 40px;
}

.slick-slider{
    .slick-slide {
        a:focus {
            outline: none;
        }
        img {
            width: 100%;
        }
    }
    .arrow{
        @include arrow;
    }
    .slick-dots {
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 10px;
        &>li{
            @include carousel-selector(#fff);
            &.slick-active{
                @include carousel-selector(#95c731);
            }
            button {
                display: none;
            }
        }
    }
}


// @extends
.text-regular {
    font-size: 14px;
}

.text-large {
    font-size: 18px;
}

.vo-container{
    margin:0 auto;
    padding: 0 10px;
    height: 100%;
}

.fix-margin-mobile {
    margin-left: -30px;
    margin-right: -25px;
    padding-left: 30px;
    padding-right: 30px;
}

.hidden-mobile, .hidden-portable{
    display: none!important;
}

.pdg-0-l-xs{
    padding-left: 0;
}

.pdg-0-r-xs{
    padding-right: 0;
}

.row-xs{
    margin-left: -10px;
    margin-right: -10px;
}

.text-center-xs{
    text-align: center;
}

#back-to-top{
    position: relative;
    background-color: #262626;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #9d9d9d;
    padding: 15px;

    span{
        font-size: 50px;
        position: absolute;
        top: 0;
    }
}

body.lock, .modal-open{
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}

@import '../../widgets/modal/mobile';